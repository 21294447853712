import React from 'react';
import Navigation from '../components/Navigation';

const NotFound = () => {
    return (
        <div>
            <Navigation />
            Not found !!!
        </div>
    );
};

export default NotFound;